import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { api } from 'src/utils/api';
import { destroySession, getSession } from 'src/utils/auth';
import {
  DEFAULT_LOGON_TIMEOUT,
  LOCALSTORAGE_KEYS,
  LOGON_WARNING_TIMEOUT,
} from 'src/utils/constants';
import { API_RESOURCES } from 'src/utils/constants';
import { parseCookie } from 'src/utils/cookie';

import Modal from '../modal';

const SessionCountdown = () => {
  const [autoLogoutAfter, setAutoLogoutAfter] = useState(null);
  const [qrLogoutAfter, setQrLogoutAfter] = useState(null);
  const [timeLeft, setTimeLeft] = useState(Math.floor(DEFAULT_LOGON_TIMEOUT / 1000));
  const interval = useRef(null);
  const typingTimeout = useRef(null);
  const { addToast } = useToasts();

  const getQrLogoutSettings = async () => {
    try {
      const { resources } = await api.get(`${API_RESOURCES.BUREAU_SETTINGS}/`).json();
      setAutoLogoutAfter(resources[0].autologout_after * 60);
      setQrLogoutAfter(resources[0].contactless_logon_timeout * 60);
      setTimeLeft(resources[0].contactless_logon_timeout * 60);
    } catch (error) {
      addToast(error.message, { appearance: 'error' });
      console.log(error);
    }
  };

  const handleQrLogout = () => {
    setTimeLeft(timeLeft => {
      const updatedTimeLeft = timeLeft - 1;

      if (updatedTimeLeft <= 0) {
        handleLeave();
      }

      return updatedTimeLeft;
    });
  };

  const handleNormalLogout = () => {
    // Retrieve the auto logout time (expiration) from the cookie
    let { autologoutAfter: logoutDateTime } = parseCookie(document.cookie);

    // Save the logout time in localStorage to persist it across page refreshes, reopenings, and PWA suspensions
    if (logoutDateTime) {
      localStorage.setItem(LOCALSTORAGE_KEYS.AUTOLOGOUT_AFTER, logoutDateTime);
    }

    logoutDateTime = logoutDateTime || localStorage.getItem(LOCALSTORAGE_KEYS.AUTOLOGOUT_AFTER);

    if (logoutDateTime) {
      // Calculate the remaining time until logout
      const timeDelta = new Date(logoutDateTime).getTime() - new Date().getTime();
      const timeLeft = Math.max(Math.floor(timeDelta / 1000), 0);
      setTimeLeft(timeLeft);

      if (timeLeft <= 0) {
        handleLeave();
      }
    }
  };

  /**
   * Manages the automatic logout process based on QR code login status and timeout settings.
   * It clears any existing logout interval and sets a new interval to check the logout conditions periodically.
   * If QR code login is enabled and the QR logout timeout is less than the normal auto logout timeout,
   * it triggers QR code-based logout. Otherwise, it triggers normal logout.
   *
   * @returns {void}
   */
  const handleAutoLogout = () => {
    window.clearInterval(interval.current);
    const { contactlessLoginEnabled } = parseCookie(document.cookie);

    interval.current = window.setInterval(() => {
      if (contactlessLoginEnabled && qrLogoutAfter < autoLogoutAfter) {
        handleQrLogout();
      } else {
        handleNormalLogout();
      }
    }, 1000);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) return;
    handleAutoLogout();
  };

  const handleKeepSessionAlive = () => {
    getSession();
    setTimeLeft(qrLogoutAfter || autoLogoutAfter);
    handleAutoLogout();
  };

  const handleUserTyping = () => {
    clearTimeout(typingTimeout.current);
    window.clearInterval(interval.current);

    typingTimeout.current = setTimeout(() => {
      handleKeepSessionAlive();
      handleAutoLogout(); // Restart the auto-logout interval
    }, 3000);
  };

  const handleLeave = () => {
    if (interval.current) {
      window.clearInterval(interval.current);
    }

    destroySession()
      .catch(error => console.error(error))
      .finally(() => {
        localStorage.removeItem(LOCALSTORAGE_KEYS.AUTOLOGOUT_AFTER);
        location.reload();
      });
  };

  useEffect(() => {
    getQrLogoutSettings();
  }, []);

  useEffect(() => {
    handleAutoLogout();
    return () => window.clearInterval(interval.current);
  }, [qrLogoutAfter]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (interval.current) {
        window.clearInterval(interval.current);
      }
    };
  }, []);

  // Effect to handle standalone WebApp reopening
  useEffect(() => {
    window.addEventListener('pageshow', handleAutoLogout);
    return () => {
      window.removeEventListener('pageshow', handleAutoLogout);
    };
  }, []);

  // Listener to track the user typing activity on the page
  useEffect(() => {
    document.addEventListener('keydown', handleUserTyping);
    return () => {
      document.removeEventListener('keydown', handleUserTyping);
      clearTimeout(typingTimeout.current);
      window.clearInterval(interval.current);
    };
  }, []);

  return (
    <>
      {timeLeft <= LOGON_WARNING_TIMEOUT / 1000 && (
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='workstation-is-overloaded'>Current session expiration time</Tooltip>
          }
        >
          <button
            type='button'
            className='btn text-reset text-white'
            aria-label='Session exiration'
          >
            {timeLeft}
          </button>
        </OverlayTrigger>
      )}
      <Modal
        isOpen={timeLeft <= LOGON_WARNING_TIMEOUT / 1000}
        title='Your session is about to expire'
        dismissText='Logout'
        confirmText='Keep Session Alive'
        onConfirm={handleKeepSessionAlive}
        onDismiss={handleLeave}
      >
        <div className='modal-body'>
          <p>Your session will expire in {timeLeft} seconds.</p>
        </div>
      </Modal>
    </>
  );
};

export default SessionCountdown;
